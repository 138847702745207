// Breakpoints
$bp-desktop-lg: 2560px;
$bp-desktop-md: 1920px;
$bp-desktop-sm: 1680px;

$bp-tablet-lg: 1400px;
$bp-tablet-md: 1280px;
$bp-tablet-sm: 1024px;

$bp-mobile-lg: 860px;
$bp-mobile-md: 768px;
$bp-mobile-sm: 500px;
$bp-mobile-xs: 360px;

$border-transparent: 2px solid rgba(0, 0, 0, 0.1);