

.hero {
    background: #F4F4F4;
    position: relative;
    min-height: 900px;
    padding-top: 100px;

    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpointDown($bp-desktop-md) {
        min-height: 680px;
    }

    @include breakpointDown($bp-tablet-md) {
        padding-top: 75px;
        min-height: 368px;
    }

    @include breakpointDown($bp-mobile-md) {
        min-height: 265px;
    }

    @include breakpointDown($bp-mobile-xs) {
        min-height: 217px;
    }

    .contentWrapper {
        position: relative;
        max-width: 1650px;
        height: 100%;
        margin: unset;
    }

    .contentPadding {
        padding-right: 0 !important;
    }

    p {
        font-family: 'Proxima Nova Semibold', sans-serif;
        font-size: 24px !important;
        line-height: 35px;

        @include breakpointDown($bp-tablet-md) {
            font-size: 18px !important;    
            line-height: 25px;
        }
        
        @include breakpointDown($bp-mobile-md) {
            font-size: 12px !important;
            line-height: 18px; 
        }

        @include breakpointDown($bp-mobile-sm) {
            font-size: 10px !important;
            line-height: 16px;
        }
    }

    &__bg {
        background: var(--c-black);
        width: 66%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    
        display: flex;
        align-items: flex-start;

        @include breakpointDown(625px) {
            width: 100%;
        }

        &--graphic {
            background: url('../assets/svg/ruler-vertical.svg');
            background-position: top left;
            background-size: contain;
            width: 25px;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            z-index: 3;
        }
    }

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        color: var(--c-white);
    
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        flex: 1;

        column-gap: 100px;

        @include breakpointDown($bp-desktop-md) {
            column-gap: 75px;
        }

        @include breakpointDown($bp-tablet-md) {
            column-gap: 35px;
        }

        @include breakpointDown($bp-mobile-md) {
            column-gap: 20px;
        }
    }
    
    &__copy {
        max-width: max-content;
        flex: 0 0 40%;
        min-width: 375px;

        @include breakpointDown(1800px) {
            margin-left: 25px;
        }

        @include breakpointDown($bp-mobile-lg) {
            min-width: unset !important;
            max-width: 375px;
            flex: 1 0 40%;
        }

        @include breakpointDown($bp-mobile-lg) {
            min-width: 305px;
            max-width: 305px;
        }

        @include breakpointDown(625px) {
            max-width: 100%;
            flex: 0 0 100%;
            min-width: 100%;
            padding: 0 50px;
            margin-left: 0;
        }
    }
    
    &__img {
        flex: 1;

        &--desktop {
            display: block;
            max-width: 960px;

            @include breakpointDown($bp-mobile-lg) {
                display: none;
            }
        }

        &--mobile {
            display: none;

            @include breakpointDown($bp-mobile-lg) {
                display: block;
            }

            @include breakpointDown(625px) {
                display: none;
            }
        }
    }    
}





