.nav {
    width: 100%;
    z-index: 4;
    position: fixed;
    top: 0;
    left: 0;
    user-select: none;

    li {
        margin: 0;
        list-style-type: none;
    }

    .contentWrapper[data-wrapper="nav"] {
        background: var(--c-white);
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        column-gap: 50px;
        height: 100px;
        padding: 0 50px;
        box-sizing: border-box;
        box-shadow: var(--box-shadow-light);

        @include breakpointDown(1800px) {
            max-width: unset !important;
        }

        @include breakpointDown($bp-tablet-md) {
            padding: 0;
        }

        @include breakpointDown($bp-mobile-md) {
            height: 75px;
        }
    }

    &__content {
        &--desktop {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            align-items: center;
            flex: 1;
            column-gap: 50px;

            @include breakpointDown($bp-mobile-md) {
                justify-content: flex-end;
            }

            @include breakpointDown($bp-mobile-md) {
                display: none;
            }
        }

        &--mobile {
            display: none;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            flex: 1;
            animation: navFadeIn 0.2s ease-in;

            @include breakpointDown($bp-tablet-md) {
                display: none;

                &.active {
                    display: flex;
                }

                
            }

            &-wrapper {
                background: var(--c-black);
                width: 100%;
                position: relative;
            }

            &--graphic {
                background: url('../assets/svg/ruler-vertical.svg');
                background-position: top left;
                background-size: contain;
                width: 25px;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
            }

            .nav__item {
                display: flex;
                justify-content: center;
                align-items: center;
                flex: unset;
                height: 75px;
                width: 100%;
            }
        }
    }

    &__logo {
        @include breakpointDown($bp-tablet-md) {
            padding-left: 25px;
        }

        img {
            max-width: 250px;

            @include breakpointDown($bp-tablet-sm) {
                max-width: 170px;
            }

            @include breakpointDown($bp-mobile-lg) {
                max-width: 130px;
            }
        }
    }

    &__links {
        &--desktop {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-around;
            column-gap: 25px;
            flex: 1;

            @include breakpointDown($bp-tablet-md) {
                display: none;
            }
        }

        &--mobile {
            color: white;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-size: 20px;
        }
    }

    &__toggle {
        display: none;
        height: 30px;
        width: 30px;
        margin-right: 25px;
        cursor: pointer;

        background: url('../assets/svg/icon-hamburger-menu.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        @include breakpointDown($bp-tablet-md) {
            display: block;
        }

        &.active {
            background: url('../assets/svg/close-black.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
    
        }
    }

    &__item {
        transition: border 0.2s ease-in;

        span {
            padding-bottom: 5px;

            &:hover {
                padding-bottom: 3px;
                border-bottom: 2px solid var(--c-orange);
            }
    
        }
       
        &.active {
            span {
                padding-bottom: 3px;
                border-bottom: 2px solid var(--c-orange);
                transition: border 0.2s ease-in;
            }
        }
    }

    &__phone {
        &--icon {
            img {
                height: 30px;
            }
        }

        &--copy {
            display: flex;
            flex-flow: column nowrap;
        }

        &--desktop {
            display: flex;
            flex-flow: row nowrap;
            column-gap: 10px;
            min-width: max-content;

            @include breakpointDown($bp-mobile-lg) {
                display: none;
            }
        }

        &--mobile {
            display: none !important;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            column-gap: 10px;
            width: 100%;
            height: 75px;
            background: var(--c-white);
            box-shadow: var(--box-shadow-light);

            @include breakpointDown($bp-mobile-lg) {
                display: flex !important;
            }
        }
    }

    &__action {
        &--desktop {
            display: flex;

            @include breakpointDown($bp-mobile-lg) {
                display: none;
            }
        }

        &--mobile {
            display: none;
            justify-content: center;
            align-items: center;
            width: 100%;
            color: white;
            height: 75px;

            @include breakpointDown($bp-mobile-lg) {
                display: flex;
            }
        }
    }
}

@keyframes navFadeIn {
   from {
        transform: translateX(-100%);
        opacity: 0.5;
    } to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes navFadeOut {
   from {
        transform: translateX(0);
        opacity: 1;

    } to {
        transform: translateX(-100%);
        opacity: 0.5;
    }
}

.navFadeOut {
    animation: navFadeOut 0.2s ease-in;
}