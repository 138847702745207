.about {    
    &__content {
        text-align: start;
        column-gap: 30px;

        @include breakpointDown($bp-tablet-sm) {
            column-gap: 0;
        }

        @include breakpointDown($bp-mobile-md) {
            text-align: center;
        }
    }

    &__img {
        box-shadow: var(--box-shadow-light);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        @include breakpointDown($bp-tablet-sm) {
            flex: 1 0 33% !important;
        }

        @include breakpointDown($bp-mobile-md) {
            display: none;
        }
        
        &--desktop {
            display: block;

            @include breakpointDown($bp-tablet-md) {
                display: none;
            }
        }

        &--tablet {
            display: none;

            @include breakpointDown($bp-tablet-md) {
                display: block;
            }
            @include breakpointDown($bp-mobile-lg) {
                display: none;
            }
        }

        &--mobile {
            display: none;
            @include breakpointDown($bp-mobile-lg) {
                display: block;
            }
            @include breakpointDown($bp-mobile-md) {
                display: none;
            }
        }
    }
}