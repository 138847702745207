

.availableLots {    
    background-image: url('../assets/images/available-lots-2560.jpg');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--c-white);
    min-height: 352px;

    display: flex;
    justify-content: center;
    align-items: center;

    h1, p {
        text-shadow: 2.5px 2.5px 5px rgba(0,0,0, 0.5);
    }

    @include breakpointDown($bp-mobile-lg) {
        min-height: 210px
    }

    &__header {
        &--desktop {
            display: block;
            @include breakpointDown($bp-tablet-sm) {
                display: none;
            }
        }
        
        &--mobile {
            display: none;
            @include breakpointDown($bp-tablet-sm) {
                display: block;
            }
        }
    }

    &__copy {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__icon {
        @include breakpointDown($bp-tablet-sm) {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        @include breakpointDown($bp-mobile-md) {
            display: none;
        }        
    }
}
