.values {
    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;

    background-color: var(--c-light-grey);
    background-image: url('../assets/images/img-values-2560.jpg');
    background-position: top left;
    background-size: contain;
    background-repeat: no-repeat;

    height: 567px;

    @include breakpointDown($bp-desktop-md) {
        height: 519px;
    }

    @include breakpointDown(1745px) {
        background-image: none;
        height: 467px;
    }

    @include breakpointDown($bp-mobile-md) {
        height: 287px;
    }

    @include breakpointDown($bp-mobile-sm) {
        height: 232px;
    }

    @include breakpointDown($bp-mobile-xs) {
        height: 258px;
    }

    &__content {
        max-width: 1162px;
    }

    p {
        font-family: 'Proxima Nova Semibold', sans-serif;
        font-size: 24px !important;
        line-height: 35px;

        @include breakpointDown($bp-tablet-md) {
            font-size: 18px !important;    
            line-height: 25px;
        }
        
        @include breakpointDown($bp-mobile-md) {
            font-size: 12px !important;
            line-height: 18px; 
        }

        @include breakpointDown($bp-mobile-sm) {
            font-size: 10px !important;
            line-height: 16px;
        }
    }

    &__cards {
        font-family: 'Proxima Nova Regular', sans-serif;
        column-gap: 25px;
        justify-content: space-between;

        @include breakpointDown($bp-mobile-lg) {
            column-gap: 10px;
        }
    }

    &__card {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;

        &--img {
            display: block;
            height: 80px;
            width: 80px;
            margin-bottom: 25px;

            @include breakpointDown($bp-desktop-md) {
                height: 50px;
                height: 50px;
                margin-bottom: 10px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
    }

    &__copy {
        font-family: 'Proxima Nova Semibold', sans-serif;
        margin-bottom: 0;
    }
}

