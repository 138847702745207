#map {
    height: 846px;

    @include breakpointDown($bp-tablet-md) {
        height: 564px;
    }

    @include breakpointDown($bp-mobile-md) {
        height: 320px;
    }
}
