.testimonials {    
    &__swiper {
        overflow: hidden;
        position: relative;

        .testimonials__swiper-prev--mobile {
            display: none;

            position: absolute;
            top: 50%;
            left: 0;
            z-index: 2;

            @include breakpointDown($bp-mobile-lg) {
                display: block;
            }
        }

        .testimonials__swiper-next--mobile {
            display: none;

            position: absolute;
            top: 50%;
            right: 0;
            z-index: 2;

            @include breakpointDown($bp-mobile-lg) {
                display: block;
            }
        }

        .swiper-slide {
            max-width: 450px;

            @include breakpointDown($bp-tablet-md) {

                width: 32%;    
            }
            @include breakpointDown($bp-tablet-sm) {
                width: 250px;    

                width: 375px;    
            }
            @include breakpointDown($bp-mobile-md) {
                width: 250px;    
            }
        }

        &--controls {
            &-desktop {
                display: flex;
                flex-flow: row nowrap;
                column-gap: 10px;
                margin-bottom: 10px;

                @include breakpointDown($bp-mobile-lg) {
                    display: none;
                }
            }
        }
    }

    &__content {
        @include breakpointDown($bp-mobile-md) {
            .contentWrapper {
                max-width: unset;
            }
        }
    }

    &__card {
        background: var(--c-black);
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        color: var(--c-white);
        height: 568px;

        @include breakpointDown($bp-desktop-md) {
            height: 551px;    
        }

        @include breakpointDown($bp-tablet-md) {
            height: 400px;    
        }
    
        @include breakpointDown($bp-tablet-md) {
            height: 400px;
        }
    
        @include breakpointDown($bp-mobile-md) {
            height: 300px;
        }

        h2 {
            color: var(--c-orange);
            margin: 0;
        }
        
        p {
            color: var(--c-white);
        }

        &--content {
            padding: 20px;
            line-height: 35px;
           

            @include breakpointDown($bp-tablet-md) {
                line-height: initial;
            }

            p {
                margin: 0;
            }
        }

        &--footer {
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-end;
            flex: 1;
        }

        &--source {
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            padding: 0 20px;

            h2 {
                @include breakpointDown($bp-tablet-md) {
                    font-size: 33px;
                }
                @include breakpointDown($bp-mobile-md) {
                    font-size: 16px;
                }
            }

            p {
                margin: 0 0 10px 0;
                color: var(--c-light-grey);
            }
        }

        &--graphic {
            background: url('../assets/svg/ruler.svg');
            background-size: contain;
            background-position: bottom center;
            height: 25px;
        }
    }
}