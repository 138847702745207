.contactPopup {
  position: relative;

  p {
    margin: 0;
  }

  &__close {
    position: absolute;
    top: 30px;
    right: 30px;
    height: 30px;
    width: 30px;
    z-index: 10;
    background: url('../assets/svg/close-orange.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;

    @include breakpointDown($bp-tablet-sm) {
      top: 15px;
      right: 15px;
      height: 15px;
      width: 15px;  
    }
  }

  .modal__container {
    background: url('../assets/images/popup.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 1238px;
    max-width: 85vw;
    // height: 928px;
    max-height: 95vh;
    padding: 30px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    @include breakpointDown($bp-tablet-sm) {
      padding: 15px;
    }
  }

  color: var(--c-white);

  &__content {
    width: 100%;
    max-width: 705px;
    margin: auto;

    display: flex;
    flex-flow: column nowrap;
    row-gap: 15px;
  }

  &__form {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
    row-gap: 15px;
    

    input, select, textarea, option {
      outline: none;
      appearance: none;
      resize: none;
      border: none;
      padding: 10px 25px;
      color: var(--c-black) !important;
      font-size: 20px;
      font-family: 'Proxima Nova Semibold', sans-serif;
      height: 60px;
      border: 1px solid white;
      transition: border 0.2s ease-in-out;

      @include breakpointDown($bp-tablet-sm) {
        padding: 10px;
        font-size: 12px;
      }

      @include breakpointDown($bp-tablet-sm) {
        height: 30px;
      }

      &::placeholder {
        color: var(--c-black) !important;
        font-size: 20px;
        font-family: 'Proxima Nova Semibold', sans-serif;

        @include breakpointDown($bp-tablet-sm) {
          font-size: 12px;
        }
  
      }

      &[type='file'] {
        padding: 0;
        background: var(--c-white);
      }
    }

    select {
      padding: 0;
    }

    textarea {
      height: 120px;
      padding: 25px;

      @include breakpointDown($bp-tablet-sm) {
        padding: 10px;
      }

      @include breakpointDown($bp-tablet-sm) {
        height: 60px;
      }
    }
  }

  .contactPopup__input {
    flex: 1 0 100%;

    &--50 {
      flex: 1 0 45% !important;
    }

    &--select {
      height: 60px;
      position: relative;
      cursor: pointer;
      flex: 1 0 100%;

      &--50 {
        flex: 1 0 48%;
      }

      @include breakpointDown($bp-tablet-sm) {
        height: 30px;
      }
      
      select {
        width: 100%;
        margin: 0;

        padding: 0 10px;
      }

      .icon {
        top: 20%;
        right: 10px;
        position: absolute;
        background: url('../assets/svg/select-arrow.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 10px;
        height: 20px;
        z-index: 10;
        margin: 0;
      }
    }

    &--file {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      background: var(--c-white);
      position: relative;
      height: 60px;
      flex: 1 0 100%;
      padding: 0;
      color: var(--c-black) !important;
      font-size: 20px;
      font-family: 'Proxima Nova Semibold', sans-serif;
      cursor: pointer;

      &.hidden {
        display: none;
      }

      @include breakpointDown($bp-tablet-sm) {
        font-size: 12px;
      }

      @include breakpointDown($bp-tablet-sm) {
        height: 40px;
      }

      button {
        height: 100%;
      }

      label {
        flex: 1;
        padding: 10px 25px;

        @include breakpointDown($bp-tablet-sm) {
          padding: 10px;
        }
      }
      
      input {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        opacity: 0;
      }
    }
  }

  #contactResponse {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    margin: 0;

    &.active {
      height: 30px;
      opacity: 1;
      transition: opacity 0.2s ease-in-out;  
    }
  }

  .input-error {
    border: 1px solid red;
    transition: border 0.2s ease-in-out;
  }
}