@font-face {
    font-family: 'Proxima Nova Regular';
    src: url('../assets/fonts/proximanova-regular-webfont.ttf');
}
@font-face {
    font-family: 'Proxima Nova Medium';
    src: url('../assets/fonts/Proxima-Nova-Medium.ttf');
}
@font-face {
    font-family: 'Proxima Nova Semibold';
    src: url('../assets/fonts/Proxima-Nova-Sbold.otf');
}
@font-face {
    font-family: 'Proxima Nova Bold';
    src: url('../assets/fonts/Proxima-Nova-Bold.otf');
}
@font-face {
    font-family: 'Proxima Nova Bold Italic';
    src: url('../assets/fonts/Proxima-Nova-Bold-It.otf');
}