.btn {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    background: var(--c-orange);
    color: var(--c-white);

    padding: 20px;
    transition: background 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;

    &:hover {
        background: var(--c-light-grey);
        color: var(--c-orange);
        transition: background 0.2s ease-in-out;
        transition: color 0.2s ease-in-out;    
    }

    @include breakpointDown($bp-tablet-md) {
        padding: 10px;
    }

    &-arrow {
        &:hover {    
            span::after {
                background: url('../assets/svg/btn-arrow-orange.svg');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;    
            }
        }
    
        span {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
    
            &::after {
                content: "";
                width: 25px;
                height: 25px;
                display: inline-flex;
                margin: 0;
                transform: rotate(-90deg);
                background: url('../assets/svg/btn-arrow-white.svg');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;

                @include breakpointDown($bp-mobile-lg) {
                    width: 15px;
                    height: 15px;    
                }
            }
        }
    }
 }