.swiper-pagination-bullets {
    display: flex;
    justify-content: center;
    padding: 15px 0;
}

.swiper-pagination-bullet {
    color: var(--c-light-grey) !important;
}

.swiper-pagination-bullet-active {
    background: var(--c-dark-grey) !important;
}

.swiper-slide {
    -webkit-transform: translateZ(0) !important;
    -webkit-backface-visibility: hidden !important;
}

.swiper-prev {
    background-image: url('../assets/svg/btn-prev.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    height: 45px;
    width: 45px;

    transition: background 0.1s ease-in-out;

    &:hover {
        background-image: url('../assets/svg/btn-prev-h.svg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        transition: background 0.1s ease-in-out;
    }
}

.swiper-next {
    background-image: url('../assets/svg/btn-next.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    height: 45px;
    width: 45px;

    transition: background 0.1s ease-in-out;

    &:hover {
        background-image: url('../assets/svg/btn-next-h.svg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        transition: background 0.1s ease-in-out;
    }
}