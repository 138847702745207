.nextSteps {
    &__img {
        background-image: url('../assets/images/next-steps-2560.jpg');
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 835px;

        @include breakpointDown($bp-desktop-md) {
            height: 674px;
        }
        @include breakpointDown($bp-tablet-md) {
            height: 450px;
        }
        @include breakpointDown($bp-mobile-lg) {
            height: 269px;
        }
        @include breakpointDown($bp-mobile-sm) {
            height: 175px;
        }
        @include breakpointDown($bp-mobile-xs) {
            height: 192px;
        }
    }
    
    h1 {
        color: var(--c-white);
    }

    &__container {
        background: var(--c-white);
        color: var(--c-white);
        padding: 35px 0;
        box-shadow: var(--box-shadow-light);
        // border-radius: 15px;

        @include breakpointDown($bp-mobile-lg) {
            flex-flow: row wrap !important;
            padding: 10px;    
        }
    }
    
    &__content {
        margin: -345px auto 0 auto;

        @include breakpointDown($bp-desktop-md) {
            margin: -200px auto 0 auto;

        }
        @include breakpointDown($bp-tablet-md) {
            margin: -165px auto 0 auto;
        }
        @include breakpointDown($bp-mobile-lg) {
            margin: -165px auto 0 auto;
        }
        @include breakpointDown($bp-mobile-sm) {
            margin: -65px auto 0 auto;
        }
        @include breakpointDown($bp-mobile-xs) {
            margin: -85px auto 0 auto;
        }

        .contentWrapper {
            @include breakpointDown($bp-mobile-sm) {
                max-width: unset !important;
            }
        }
    }
    
    &__item {
        padding: 0 35px;
        color: var(--c-black);

        @include breakpointDown($bp-mobile-lg) {
            padding: 10px 25px;
        }

        @include breakpointDown($bp-mobile-sm) {
            flex: 1 0 48% !important;
            border-right: 0 !important;
        }

        &--number {
            margin-bottom: 10px;
            width: 75px;
            height: 75px;

            &[data-step='1'] {
                width: 60px;
                height: 60px;    
            }

            @include breakpointDown($bp-mobile-sm) {
                width: 50px;
                height: 50px;

                &[data-step='1'] {
                    width: 35px;
                    height: 35px;    
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }

        h2 {
            @include breakpointDown($bp-tablet-lg) {
                font-size: 30px;
            }
            @include breakpointDown($bp-tablet-md) {
                font-size: 18px;
            }
            @include breakpointDown($bp-mobile-sm) {
                font-size: 12px;
            }
        }
        
        @include breakpointUp($bp-mobile-lg) {
            &:not(:last-child) {
                border-right: 2px solid rgba(0, 0, 0, 0.1);
            }
        }
    }

    &__divider {
        @include breakpointDown($bp-mobile-lg) {
            border-bottom: 2px solid rgba(0, 0, 0, 0.1) !important;
            flex: 1 0 100%;
        }
        @include breakpointUp($bp-mobile-lg) {
            margin: 15px 0;
        }
    }

    &__mobile {
        @include breakpointDown($bp-mobile-lg) {
            border-right: 2px solid rgba(0, 0, 0, 0.1) !important;
        }
    }
}