.whereWeBuild {

    &__header {
        &--copy {
            text-align: center;
        }
    }
    #googleMap {
        background: url('../assets/images/map.png') var(--c-dark-grey);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        height: 846px;
        width: 100%;
        z-index: -1;

        @include breakpointDown($bp-tablet-md) {
            height: 564px;
        }

        @include breakpointDown($bp-mobile-lg) {
            height: 339px;
        }
    }

    h1 {
        text-align: center;
    }

    &__content {
        margin: auto;
        column-gap: 25px;
        row-gap: 25px;
        margin: -150px auto 0 auto;
    
        @include breakpointDown($bp-mobile-lg) {
            flex-flow: column nowrap !important;
            margin: auto;
        }
    }
    
    &__card {
        display: flex;
        flex-flow: column nowrap;
        box-shadow: var(--box-shadow-light);

        &--header {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        
            height: 415px;
            color: var(--c-white);
        
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: flex-end;

            padding: 20px 35px;

            h2 {
                margin: 0;
            }

            @include breakpointDown($bp-tablet-md) {
                height: 228px;
            }

            @include breakpointDown($bp-mobile-lg) {
                padding: 15px;
            }

            @include breakpointDown($bp-mobile-sm) {
                height: 150px;
            }

            img {
                height: 65px;

                @include breakpointDown($bp-tablet-md) {
                    height: 35px;
                }
            }

            &[data-category="agriculture"] {
                background-image: url('../assets/images/img-agriculture-2560.jpg');
            }
            &[data-category="commercial"] {
                background-image: url('../assets/images/img-commercial-2560.jpg');
            }
        }

        &--content {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            flex: 1;
        }

        &--copy {
            padding: 20px 35px;

            @include breakpointDown($bp-mobile-lg) {
                padding: 15px;
            }
        }

        &--footer {
            display: flex;
            justify-content: flex-end;
        }

    }
}