* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --c-black: #252525;
    --c-dark-grey: #50525D;
    --c-light-grey: #CECECE;
    --c-white: #fff;
    --c-orange: #F76727;
    --box-shadow-light: 0 10px 10px #20202010;
}

html, body {
    scroll-behavior: smooth;
    scroll-padding-top: 125px;

}

body {
    color: var(--c-black);
    font-family: 'Proxima Nova Medium', sans-serif;
    font-weight: 400;
}

h1 {
    font-family: 'Proxima Nova Semibold', sans-serif;
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 30px;

    @include breakpointDown($bp-tablet-md) {
        font-family: 'Proxima Nova Regular', sans-serif;
        font-size: 45px;
        line-height: 55px;
    }

    @include breakpointDown($bp-mobile-lg) {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 15px;
    }

    @include breakpointDown($bp-mobile-md) {
        margin-bottom: 25px;
        font-size: 20px;
        line-height: 30px;
    }
}

h2 {
    font-family: 'Proxima Nova Regular', sans-serif;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 35px;

    @include breakpointDown($bp-tablet-md) {
        font-size: 30px;
        line-height: 35px;
    }

    @include breakpointDown($bp-tablet-sm) {
        margin-bottom: 15px;
        font-size: 18px;
        line-height: 25px;
    }

    @include breakpointDown($bp-mobile-md) {
        font-size: 14px;
        line-height: 18px;
    }

    @include breakpointDown($bp-mobile-sm) {
        font-size: 12px;
        line-height: 16px;
    }
}

p {
    font-family: 'Proxima Nova Regular', sans-serif;
    font-size: 20px;
    margin-bottom: 30px;
    line-height: 30px;


    @include breakpointDown($bp-tablet-md) {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 25px;
    }

    @include breakpointDown($bp-mobile-md) {
        font-size: 12px;
        margin-bottom: 20px;
        line-height: 16px;
    }

    @include breakpointDown($bp-mobile-sm) {
        font-size: 10px;
        margin-bottom: 15px;
    }
}

nav {
    font-family: 'Proxima Nova Semibold', sans-serif;
    font-size: 17px;

    @include breakpointDown($bp-mobile-md) {
        font-size: 14px;
    }
}

button {
    cursor: pointer;
    border: none;
    border-radius: none;
    font-size: 17px;
    font-family: 'Proxima Nova Semibold', sans-serif;

    @include breakpointDown($bp-mobile-lg) {
        font-size: 14px;
    }

    @include breakpointDown($bp-mobile-md) {
        font-size: 10px;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

img {
    user-select: none;
}

.container {
    max-width: 2560px;
    margin: auto;
}

.contentWrapper {
    max-width: 1391px;
    margin: auto;

    @include breakpointDown($bp-tablet-md) {
        max-width: 1180px;
    } 

    @include breakpointDown($bp-mobile-lg) {
        max-width: unset;
    }    
    // @include breakpointDown($bp-mobile-md) {
    //     max-width: 377px;
    // }    
}

.contentPadding {
    padding: 40px 25px;

    @include breakpointDown($bp-mobile-md) {
        padding: 35px 15px;
    }
}

.contentMargin {
    margin: 0 25px;
}

.imgContainer img {
    width: 100%;
}

.section-mb {
    margin-bottom: 125px;

    @include breakpointDown($bp-tablet-md) {
        margin-bottom: 85px;
    }

    @include breakpointDown($bp-mobile-lg) {
        margin-bottom: 50px;
    }

    @include breakpointDown($bp-mobile-md) {
        margin-bottom: 35px;
    }
}

.bold {
    font-family: 'Proxima Nova Bold', sans-serif;
}

.flex-c {
    display: flex;
    flex-flow: row nowrap;
}

.flex-100 {
    flex: 1 0 100%;
}
.flex-75 {
    flex: 1 0 73%;
}
.flex-66 {
    flex: 1 0 64%;
}
.flex-60 {
    flex: 1 0 58%;
}
.flex-50 {
    flex: 1 0 48%;
}
.flex-40 {
    flex: 1 0 38%;
}
.flex-33 {
    flex: 1 0 30%;
}
.flex-25 {
    flex: 1 0 23%;
}