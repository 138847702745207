.ourWork {
    &__header {
        text-align: center;
    }
}

.gallery {
    display: none;

    &__menu {
        display: flex;
        flex-flow: row nowrap;
        border-top: $border-transparent;

        @include breakpointDown($bp-mobile-md) {
            flex-flow: column nowrap;
        }
    }

    &.active {
        display: block;
    }

    &__checkbox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1 0 20%;
        text-transform: uppercase;
        position: relative;
        padding: 25px;
        letter-spacing: 3px;

        @include breakpointDown($bp-tablet-md) {
            padding: 15px;
        }

        @include breakpointDown($bp-mobile-md) {
            flex: 1 0 100%;
        }

        &:not(:last-child) {
            @include breakpointUp($bp-mobile-md) {
                border-right: $border-transparent;
            }
        }

        &:nth-of-type(1) {
            @include breakpointDown($bp-mobile-md) {
                border-right: none;
                border-bottom: $border-transparent;
            }
        }

        &:nth-of-type(2) {
            @include breakpointDown($bp-mobile-md) {
                border-bottom: $border-transparent;
            }
        }

        &:nth-of-type(3) {
            @include breakpointDown($bp-mobile-md) {
                border-right: none;
            }
        }

        &--label {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            column-gap: 10px;
            font-size: 14px;
            color: #2E3A35;

            @include breakpointDown($bp-tablet-md) {
                font-size: 11px;
            }

            @include breakpointDown($bp-mobile-md) {
                font-size: 9px;
            }
        }

        &--img {
            height: 40px;
            width: 40px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }

        input {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            outline: none;

            &:checked ~ .gallery__checkbox--input {
                background: url('../assets/svg/gallery-checkmark.svg') #2E3A35;
                background-size: 80%;
                background-position: center;
                background-repeat: no-repeat;
            }
        }

        &--input {
            height: 25px;
            width: 25px;
            border: 2px solid #2E3A35;
        }
    }

    &__swiper {
        position: relative;
        overflow: hidden;

        .swiper-gallery-prev {
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 2;
        }
        .swiper-gallery-next {
            position: absolute;
            top: 50%;
            right: 0;
            z-index: 2;
        }
        
        .swiper-slide {
            height: 605px;
            width: 1050px;
            max-width: 80%;
            opacity: 0.8;
            transition: opacity 0.1s ease-in-out;

            &-active {
                opacity: 1;
                transition: opacity 0.1s ease-in-out;
            }

            @include breakpointDown($bp-tablet-md) {
                height: 505px;
                width: 878px;     
            }

            @include breakpointDown($bp-mobile-md) {
                height: 303px;
                width: 527px;     
            }

            @include breakpointDown($bp-mobile-sm) {
                height: 217px;
                width: 377px;     
            }

            @include breakpointDown($bp-mobile-xs) {
                height: 186px;
                width: 323px;     
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
}