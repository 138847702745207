.footer {
    display: flex;
    flex-flow: column nowrap;
    border-top: 2px solid #E2E2E2;


    h3 {
        color: var(--c-dark-grey);
        margin-bottom: 35px;
        font-size: 20px;

        @include breakpointDown($bp-tablet-md) {
            margin-bottom: 20px;
        }
    }

    p {
        margin-bottom: 5px;
        font-size: 14px;
    }

    &__social-links {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        column-gap: 25px;
    }


    &__col {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        flex: 1 0 23%;
        padding: 40px;

        @include breakpointDown($bp-tablet-md) {
            padding: 20px;
        }

        @include breakpointDown($bp-mobile-lg) {
            padding: 10px;
        }

        
        &:not(:last-child) {
            border-right: 2px solid #E2E2E2;
        }

        &:first-child {
            justify-content: center;

            @include breakpointDown($bp-tablet-sm) {
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 1 0 100%;
                border-right: none;
                border-bottom: 2px solid #E2E2E2;
            }
        }

        &:nth-of-type(2), &:nth-of-type(3) {
            @include breakpointDown($bp-tablet-sm) {
                flex: 1 0 48%;
                justify-content: center;
                align-items: center;
                text-align: center;
            }
        }
    
        &:nth-of-type(3) {
            @include breakpointDown($bp-mobile-md) {
                border-top: 2px solid #E2E2E2;
            }
        }

        &:last-child {
            @include breakpointDown($bp-tablet-sm) {
                border-top: 2px solid #E2E2E2;
                justify-content: center;
                align-items: center;
                text-align: center;
            }
        }

        span {
            font-family: 'Proxima Nova Semibold', sans-serif;
        }

        img {
            max-width: 100%;

            @include breakpointDown($bp-mobile-lg) {
               max-height: 200px;
            }
        }
    }

    &__utility {
        padding: 30px;
        background: var(--c-black);
        color: var(--c-light-grey);
        text-align: center;
        border-bottom: 10px solid var(--c-light-grey);;
    }
    
}

.footer__content {
    width: 100%;

    @include breakpointDown($bp-tablet-sm) {
        display: flex;
        flex-flow: row wrap;
    }

    @include breakpointDown($bp-mobile-md) {
        flex-flow: column nowrap;
    }
}

.social-icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    height: 25px;
    width: 25px;
}

.fa-brands {
    font-size: 20px;
    color: var(--c-black);
    transition: color 0.2s ease-in-out;

    &:hover {
        color: var(--c-orange);
        transition: color 0.2s ease-in-out;
    }
}