.homebuilding {
    background: url('../assets/svg/homebuilding-bg.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-flow: column nowrap;
    row-gap: 25px;
    overflow: hidden;

    .contentWrapper {
        @include breakpointDown($bp-mobile-lg) {
            margin: 0;
        }
    }


    @include breakpointDown($bp-mobile-sm) {
        background: none;
    }

    &__img {
        max-height: 600px;
        
        &--desktop {
            display: block;
            @include breakpointDown($bp-tablet-md) {
                display: none;
            }
        }

        &--tablet {
            display: none;
            @include breakpointDown($bp-tablet-md) {
                display: block;
            }
            @include breakpointDown($bp-mobile-md) {
                display: none;
            }
        }

        &--mobile {
            display: none;
            @include breakpointDown($bp-mobile-md) {
                display: block;
            }
        }
        
    }

    &__swiper {
        overflow: hidden;

        &--wrapper {
            position: relative;

            .swiper-prev {
                display: none;

                position: absolute;
                top: 50%;
                left: 0;
                z-index: 3;

                @include breakpointDown(1480px) {
                    display: block;
                }
            }
    
            .swiper-next {
                display: none;

                position: absolute;
                top: 50%;
                right: 0;
                z-index: 3;

                @include breakpointDown(1480px) {
                    display: block;
                }
            }
        }

        @include breakpointDown($bp-mobile-sm) {
            .contentWrapper {
                max-width: unset;
            }
        }

        .swiper-wrapper {
            padding-bottom: 15px;
        }

        .swiper-slide {
            max-width: max-content;

            @include breakpointDown($bp-mobile-sm) {
                max-width: 50%;
            }
        }
    }

    &__content {
        @include breakpointDown($bp-mobile-sm) {
            flex-flow: column-reverse nowrap !important;
        }

        .flex-2335, .flex-66 {
            @include breakpointDown($bp-mobile-lg) {
                flex: 1 0 48%;
            }
        }
    }

    &__copy {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: flex-start;

        @include breakpointDown($bp-mobile-sm) {
            background: url('../assets/svg/homebuilding-bg.svg');
            background-size: 90%;
            background-position: center;
            background-repeat: no-repeat;
        }

        .contentPadding {
            @include breakpointDown($bp-mobile-sm) {
                padding: 0;
            }
        }
    }

    &__card {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;

        background: var(--c-white);
        box-shadow: var(--box-shadow-light);
        height: 444px;
        width: 449px;
        padding: 35px;

        @include breakpointDown($bp-tablet-md) {
            width: 375px;
            height: 379px;
            padding: 30px;
        }

        @include breakpointDown($bp-mobile-md) {
            width: 250px;
            height: 230px;
            padding: 10px;
        }

        @include breakpointDown($bp-mobile-sm) {
            width: 100%;
        }

        &--content {
            display: flex;
            flex-flow: column nowrap;
            row-gap: 15px;

            height: 100%;
    

            h2 {
                margin: 0;
            }

            p {
                margin: 0;
                flex: 1;
             }
        }
       
        &--icon {
            &-wrapper {
                align-self: flex-end;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

            }

            align-self: flex-end;
            height: 100px;
            width: 100px;

            @include breakpointDown($bp-tablet-md) {
                height: 75px;
                width: 75px;
            }

            @include breakpointDown($bp-mobile-md) {
                height: 60px;
                width: 60px;
            }

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
    }
}