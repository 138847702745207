.corporate {
    &__container {
        column-gap: 132px;

        @include breakpointDown($bp-mobile-md) {
            flex-flow: column nowrap;
        }

        @include breakpointDown($bp-tablet-md) {
            column-gap: 60px;
        }

        @include breakpointDown($bp-mobile-lg) {
            column-gap: 30px;
        }
        @include breakpointDown($bp-mobile-md) {
            column-gap: 15px;
        }
    }

    &__header {
        &--desktop {
            display: block;

            @include breakpointDown($bp-mobile-md) {
                display: none;
            }
        }

        &--mobile {
            display: none;
            text-align: center;

            @include breakpointDown($bp-mobile-md) {
                display: block;
            }
        }
    }

    &__copy {
        flex-shrink: 1;
    }
}